import request from './index.js'

//获取总机列表
export function getIvr(data = {}) {
    return request({
        url: "/api/ivr/search",
        method: "GET",
        params: data
    })
}

//总机添加
export function addIvr(data = {}) {
    return request({
        url: "/api/ivr/add",
        method: "POST",
        data
    })
}

//获取话单列表
export function cdrSearch(data = {}) {
    return request({
        url: "/api/cdr/search",
        method: "POST",
        data
    })
}

//导出话单列表
export function cdrExport(data = {}) {
    return request({
        url: "/api/cdr/export/list",
        method: "POST",
        data
    })
}

//导出录音列表
export function recordExport(data = {}) {
    return request({
        url: "/api/cdr/export/record",
        method: "POST",
        data
    })
}

//获取统计列表
export function getStat(data = {}) {
    return request({
        url: "/api/cdr/stat",
        method: "POST",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//导出统计列表
export function expStat(data = {}) {
    return request({
        url: "/api/cdr/export/stat",
        method: "POST",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}

//总机删除
export function deleteIvr(data = {}) {
    return request({
        url: "/api/ivr/delete",
        method: "DELETE",
        data
    })
}

//获取单个总机列表
export function findIvr(data = {}) {
    return request({
        url: "/api/ivr/find",
        method: "GET",
        params: data
    })
}

//总机编辑
export function updateIvr(data = {}) {
    return request({
        url: "/api/ivr/update",
        method: "PUT",
        headers: {
            "Content-Type": "application/json;chart-set:UTF-8"
        },
        data
    })
}