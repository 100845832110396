<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title mb10">{{ $t('通话记录和录音') }}</h3>
      <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            {{ $t('筛选') }}<i class="header-icon el-icon-info" style="margin-left: 3px;"></i>
          </template>
          <div>
            <el-form :model="ruleForm" size="small" label-width="100px" @keydown.enter="inquireClick">
              <el-row :gutter="35">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('时间')">
                    <div class="block" style="padding:0;">
                      <el-date-picker :shortcuts="shortcuts" v-model="value1" type="datetimerange" range-separator="-"
                                      :start-placeholder="$t('开始时间')" :end-placeholder="$t('结束时间')"
                                      :editable="false" :clearable="false"
                                      format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                      @calendar-change="calendar" :disabled-date="disableddate"
                                      @change="changeOncick" style="width:100%">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('分机号码')">
                    <el-input v-model="ruleForm.account_user" :placeholder="$t('分机号码')" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('通话方向')">
                    <el-checkbox v-model="ruleForm.checked1" checked label="1" size="large">
                      {{ $t('呼入') }}
                    </el-checkbox>
                    <el-checkbox v-model="ruleForm.checked1" checked label="0" size="large">
                      {{ $t('呼出') }}
                    </el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('通话状态')">
                    <el-checkbox v-model="ruleForm.checked2" checked label="1" size="large">
                      {{ $t('拨通') }}
                    </el-checkbox>
                    <el-checkbox v-model="ruleForm.checked2" checked label="0" size="large">
                      {{ $t('未拨通') }}
                    </el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('主叫号码')">
                    <el-input v-model="ruleForm.caller_number" :placeholder="$t('主叫号码')" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('被叫号码')">
                    <el-input v-model="ruleForm.callee_number" :placeholder="$t('被叫号码')" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('最大通话时长')">
                    <el-input v-model="ruleForm.max_time" :placeholder="$t('最大通话时长')" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item :label="$t('最小通话时长')">
                    <el-input v-model="ruleForm.min_time" :placeholder="$t('最大通话时长')" clearable></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="inquiree">
                <el-button size="small" circle icon="el-icon-refresh-left" @click="resetForm"></el-button>
                <el-button size="small" type="success" @click="exportRecord">
                  {{ $t('打包录音') }}
                </el-button>
                <el-button size="small" type="info" @click="exportCdr">{{ $t('导出') }}</el-button>
                <el-button size="small" type="primary" @click="inquireClick">{{ $t('查询') }}</el-button>
              </div>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="tableData" stripe style="width: 100%" class="table" id="out-table">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="account_user" :label="$t('分机')" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column prop="account_name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="caller_id_number" :label="$t('主叫')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="destination_number" :label="$t('被叫')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="start_stamp" :label="$t('呼叫开始时间')" show-overflow-tooltip
                         width="170"></el-table-column>
        <el-table-column prop="duration_txt" :label="$t('通话时长')" show-overflow-tooltip width="110">
        </el-table-column>
        <el-table-column prop="direction" :label="$t('呼叫方向')" show-overflow-tooltip width="110">
          <template #default="scope">
            <el-tag size="medium" v-if="scope.row.direction == 0">{{ $t('呼出') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.direction == 1">{{ $t('呼入') }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="record" label="费用（元）" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('录音文件选项')" width="150" align="center">
          <template #="scope">
            <div class="ill">
              <div class="main-icon" @click="toggleAudio(scope.$index)">
                <i :class="scope.row.audioIcon"></i>
              </div>
              <i class="el-icon-download" @click="downAudio(scope.$index)" v-show="scope.row.duration>0"></i>
              <div class="main-time">
                {{ parseInt(scope.row.currentTime) }}s/{{ scope.row.audio && parseInt(scope.row.audio.duration) }}s
              </div>
              <el-progress class="progress" @click="changeProgress($event, scope.row)" :percentage="scope.row.progress"
                           :show-text="false"></el-progress>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                     @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                     :current-page="pageNum" background :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                     :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                     :current-page="pageNum"
                     :total="total" class="mt15"></el-pagination>
    </el-card>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import moment from "moment";
import {
  cdrSearch,
  cdrExport,
  recordExport,
} from '@/http/recordsCenter.api.js';
import {ElNotification} from "element-plus";
import router from "@/router";
// import CallScreening from './Components/callScreening.vue';
export default {
  name: 'callRecords',
  setup() {
    const {t} = useI18n()
    let state = reactive({
      sysTitle: '',
      input: ref(''),
      ruleForm: {
        account_user: '',
        caller_number: '',
        callee_number: '',
        max_time: '',
        min_time: '',
        checked1: [],
        checked2: [],
      },
      activeName: 'first',
      tabPosition: 'left',
      shortcuts: [{
        text: t('当天'),
        value: () => {
          const end   = moment().format('YYYY-MM-DD HH:mm:ss')
          const start = moment(new Date().getTime()).format('YYYY-MM-DD') + ' ' + '00:00:00'
          return [start, end]
        },
      },
        {
          text: t('昨天'),
          value: () => {
            const end   = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD') + ' ' +
                '23:59:59'
            const start = moment(new Date().getTime() - 3600 * 24 * 1000).format('YYYY-MM-DD') + ' ' +
                '00:00:00'
            return [start, end]
          },
        },
        {
          text: t('本周'),
          value: () => {
            const end   = moment().endOf('isoweek').format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('isoweek').format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('上周'),
          value: () => {
            const end   = moment().endOf('isoweek').subtract('week', 1).format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('isoweek').subtract('week', 1).format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('本月'),
          value: () => {
            const end   = moment().endOf('month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
        {
          text: t('上月'),
          value: () => {
            const end   = moment().endOf('month').subtract('month', 1).endOf('month').format(
                'YYYY-MM-DD HH:mm:ss')
            const start = moment().startOf('month').subtract('month', 1).format('YYYY-MM-DD HH:mm:ss')
            return [start, end]
          },
        },
      ],
      value1: '',
      value2: '',
      pageSize: 5, //每页数据条数
      pageNum: 1, //页码
      total: 0, //总数据条数
      pageCount: 0, //总页数
      clientWidth: '',
      warningTone: [],
    });
    onMounted(() => {
      let newDate   = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
          '');
      let strDate   = new Date(new Date(new Date().toLocaleDateString()).getTime());
      var startTime = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      let arr       = [];
      arr.push(startTime)
      arr.push(newDate)
      state.value1 = arr;
      blackList(state);
      state.clientWidth = window.innerWidth;
    })

    function inquireClick() {
      blackList(state);
    }

    //时间范围选择
    function disableddate(time) {
      const nowDate = new Date();
      if (state.value2) {
        let isSelected = false;
        const startDay = (new Date(state.value2).getDate() - 1) * 24 * 3600 * 1000;
        const endDay   = (new Date(
            new Date(state.value2).getFullYear(),
            new Date(state.value2).getMonth() + 1,
            0
        ).getDate() - new Date(state.value2).getDate()) * 24 * 3600 * 1000;
        let minTime    = state.value2 - startDay;
        let Timer      = minTime + startDay;
        let maxTime    = Timer + endDay;
        return isSelected || time.getTime() > nowDate || time.getTime() > maxTime
      } else {
        return time.getTime() > nowDate;
      }
    }

    //获取开始时间并赋值给value2
    function calendar(arr) {
      state.value2 = arr[0];
    }

    //确认取消value2值
    function changeOncick() {
      state.value2 = '';
    }

    // 当前行删除
    const onRowDel              = () => {
    };
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      blackList(state);
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      blackList(state)
    };

    function exportCdr() {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        account_user: state.ruleForm.account_user,
        caller_number: state.ruleForm.caller_number,
        callee_number: state.ruleForm.callee_number,
        max_time: state.ruleForm.max_time,
        min_time: state.ruleForm.min_time,
        direction: state.ruleForm.checked1,
        type: state.ruleForm.checked2,
      }
      cdrExport(params).then(res => {
        if (res.code == 200) {
          const a         = document.createElement("a");
          a.href          = res.data.file_name;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          ElNotification({
            title: '失败',
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    function exportRecord() {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        account_user: state.ruleForm.account_user,
        caller_number: state.ruleForm.caller_number,
        callee_number: state.ruleForm.callee_number,
        max_time: state.ruleForm.max_time,
        min_time: state.ruleForm.min_time,
        direction: state.ruleForm.checked1,
        type: state.ruleForm.checked2,
      }
      recordExport(params).then(res => {
        if (res.code == 200) {
          const a         = document.createElement("a");
          a.href          = res.data.file_name;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          ElNotification({
            title: '失败',
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    function blackList(state) {
      let params = {
        start_time: state.value1[0],
        end_time: state.value1[1],
        account_user: state.ruleForm.account_user,
        caller_number: state.ruleForm.caller_number,
        callee_number: state.ruleForm.callee_number,
        max_time: state.ruleForm.max_time,
        min_time: state.ruleForm.min_time,
        direction: state.ruleForm.checked1,
        type: state.ruleForm.checked2,
        page: state.pageNum
      }
      cdrSearch(params).then(res => {
        state.warningTone = res.data.list;
        state.pageSize    = res.data.length;
        state.pageCount   = res.data.pages;
        state.total       = res.data.rows;
        state.sysTitle    = res.tag;
        if (res.tag) {
          router.push('/setUp/systemSetup')
        }
        initTable();
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    const initTable = () => {
      tableData.value = state.warningTone;
      tableData.value.map(item => {
        item.paused      = true; //是否处于暂停状态
        item.audioIcon   = "el-icon-video-play"; //播放按钮图标 默认播放
        item.currentTime = 0; //当前时间
        item.progress    = 0; //当前时间/总时长*100
        let audio        = document.createElement("audio") //创建audio
        audio.src        = item.record_url //设置路径
        audio.oncanplay  = function () {
          item.audio = audio
        }
        audio.load()
      })
    }

    let tableData     = ref([]);
    let divArr        = [];
    const toggleAudio = (index) => {
      if (tableData.value[index].paused) {
        tableData.value.map(item => { //暂停所有正在播放的音频
          if (!item.paused) {
            item.paused    = true;
            item.audioIcon = "el-icon-video-play"
            item.audio.pause();
          }
        })
        tableData.value[index].paused    = false;
        tableData.value[index].audioIcon = "el-icon-video-pause"
        tableData.value[index].audio.play();
      } else {
        tableData.value[index].paused    = true;
        tableData.value[index].audioIcon = "el-icon-video-play"
        tableData.value[index].audio.pause();
      }
      tableData.value[index].audio.addEventListener('ended', function () {
        tableData.value[index].paused    = true;
        tableData.value[index].audioIcon = "el-icon-video-play"
      }, false);
      tableData.value[index].audio.addEventListener('timeupdate', function () {
        tableData.value[index].currentTime = this.currentTime
        tableData.value[index].progress    = parseInt((this.currentTime / this.duration) * 100)
      }, false);
    }

    const downAudio = (index) => {
      var name = tableData.value[index].id;
      var url  = tableData.value[index].record_url;
      fetch(url).then(res => res.blob()).then(blob => {
        const a         = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        a.download      = name;
        a.href          = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      })
    }

    function getPercent(e) {
      let mouseX        = e.pageX; //鼠标距离屏幕左侧的距离
      let progress      = e.srcElement; //进度条div元素
      let progressWidth = progress.offsetWidth; //进度条宽度
      let progressLeft  = progress.offsetLeft; //当前点击进度条距离父元素左侧的距离
      let parent        = progress.offsetParent; //当前点击进度条的父元素
      let currentPonit  = 0; //鼠标点击当前进度条的位置
      let percent       = 0; //百分比,最后保留两位小数
      while (parent != null) { //循环获取当前元素距离父元素右侧的左边距 直到当前元素没有父元素
        progressLeft += parent.offsetLeft;
        parent = parent.offsetParent;
      }
      currentPonit = mouseX - progressLeft; //当前所在位置 等于鼠标距屏幕左边距 - 元素距离屏幕左边距
      // 百分比等于鼠标当前位置/进度条宽度
      percent = parseFloat(((currentPonit / progressWidth) * 100).toFixed(2));
      return percent
    }

    const changeProgress = (e, row) => {
      let percent     = getPercent(e);
      let currentTime = row.audio.duration * percent / 100;

      tableData.value.map(item => {
        if (!item.paused) {
          item.paused    = true;
          item.audioIcon = "el-icon-video-play"
          item.audio.pause();
        }
      })

      row.audio.currentTime = currentTime; //设置当前播放时间(点击位置)
      row.audio.play();
      row.paused    = false; //切换状态
      row.audioIcon = "el-icon-video-pause" //切换状态图标

      //两个监听事件 不多说了
      row.audio.addEventListener('ended', function () {
        row.paused    = true;
        row.audioIcon = "el-icon-video-play"
      }, false);
      row.audio.addEventListener('timeupdate', function () {
        row.currentTime = this.currentTime
        row.progress    = parseInt((this.currentTime / this.duration) * 100)
      }, false);
    }

    const resetForm = () => {
      state.ruleForm.account_user  = "";
      state.ruleForm.caller_number = "";
      state.ruleForm.callee_number = "";
      state.ruleForm.max_time      = "";
      state.ruleForm.min_time      = "";
      state.ruleForm.checked1      = ["0", "1"];
      state.ruleForm.checked2      = ["0", "1"];

      let newDate   = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
          '');
      let strDate   = new Date(new Date(new Date().toLocaleDateString()).getTime());
      var startTime = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      let arr       = [];
      arr.push(startTime)
      arr.push(newDate)
      state.value1 = arr;
    }

    return {
      ...toRefs(state),
      exportRecord,
      exportCdr,
      resetForm,
      onRowDel,
      onHandleSizeChange,
      onHandleCurrentChange,

      tableData,
      divArr,
      toggleAudio,
      downAudio,
      changeProgress,
      inquireClick,
      disableddate,
      calendar,
      changeOncick,
      initTable,
      blackList,
    };
  },
}
</script>
<style lang="scss">
.table {

  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }

}

.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.ml10 {
  margin-left: 10px;
}

.main-icon {
  font-size: 18px;
  text-align: center;
  transition: all .3s;
  display: inline-block;
}

.ill i {
  padding: 0 5px;
}

@media only screen and (max-width: 550px) {
  .block {
    width: 100% !important;
  }

  //.block /deep/ .el-input__inner {
  //  width: 100% !important;
  //}
}

.el-form-item__label {
  padding: 0 12px 0 0 !important;
}
</style>
